import request from '@/utils/request'

export function getRecommendList (data) {
    return request({
        url: '/shd-operation/recommendedForU/queryRecommendedForUGoods',
        method: 'post',
        data
    })
}

export function addRecommendedGoods(data) {
    return request({
        url: '/shd-operation/recommendedForU/addRecommendedGoods',
        method: 'post',
        data
    })
}

export function delRecommendedGoods(data) {
    return request({
        url: '/shd-operation/recommendedForU/delRecommendedGoods',
        method: 'post',
        data
    })
}
// 获取门店列表
export function listOfPage() {
  return request({
    url: "/goods-service/store/listOfPage",
    method: "get"
  });
}
// 获取分类列表
export function queryGoodsType() {
    return request({
      url: "/shd-operation/lrGoodsClassify/queryGoodsType",
      method: "get"
    });
  }
// 排序
export function updateSort(data) {
  return request({
    url: "shd-operation/recommendedForU/updateSort",
    method: "post",
    data
  });
}